import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../templates/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import ReactMarkdown from "react-markdown"

const PositionDetails = ({ data: { graphCmsPosition } }) => {
  return (
    <Layout isHeaderFixed={false}>
      <SEO
        title={graphCmsPosition?.name}
        description="Get fast and reliable solution for your digital problem, We got you covered with all software from Website development to ECommerce site to Mobile app to AI enabled drones."
      />

      <div className="my-8 max-w-screen-xl md:mx-auto mx-4">
        <Link to="/careers">
          <button className="my-4 rounded bg-primary-100 text-primary-700 px-3 py-2 focus:outline-none cursor-pointer hover:bg-primary-200 transition duration-300 ease-in-out flex items-center justify-center">
            <svg
              class="w-6 h-6 mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <div className="align-bottom">All open positions</div>
          </button>
        </Link>
        <div className="my-8 flex flex-wrap justify-between items-center">
          <div>
            <div className="text-2xl font-bold mb-2">
              {graphCmsPosition?.name}
            </div>
            <div className="mb-2 flex justify-between flex-wrap items-center">
              <span className="mr-2 whitespace-nowrap">
                Location:
                {graphCmsPosition?.location.map(location => (
                  <span className="py-1 px-2 mr-1 rounded-full bg-yellow-100 text-yellow-800 text-sm">
                    {`${location}`}
                  </span>
                ))}
              </span>
              <div className="flex space-x-4">
                {graphCmsPosition.duration && (
                  <div className="md:ml-4 mt-2 md:mt-0">
                    Duration: {graphCmsPosition.duration}{" "}
                  </div>
                )}
                {graphCmsPosition.stipend && (
                  <div className="md:ml-4 mt-2 md:mt-0">
                    Stipend: {graphCmsPosition.stipend}{" "}
                  </div>
                )}
              </div>
              {graphCmsPosition.applyBy && (
                <div className="md:ml-4 mt-2 md:mt-0">
                  Apply By: {graphCmsPosition.applyBy}{" "}
                </div>
              )}
            </div>
          </div>
          <a href="mailto:hello@annwntech.com">
            <button className="mt-4 md:mt-0 p-3 bg-primary-500 cursor-pointer text-center focus:outline-none text-white rounded hover:bg-primary-600">
              Apply Now
            </button>
          </a>
        </div>
        <div className="my-4">
          <div className="text-lg font-bold mb-2">About the job:</div>
          <ReactMarkdown className="prose lg:prose-lg prose-purple text-black">
            {graphCmsPosition.description.markdown}
          </ReactMarkdown>
        </div>
        <div className="text-lg font-bold">
          <div>Skills required:</div>
          <div className="mt-4 flex items-center flex-wrap">
            {graphCmsPosition?.skillRequired.map(skill => (
              <div className="py-2 px-3 mr-2 my-2 rounded-full bg-red-100 text-red-900 whitespace-nowrap">
                {`${skill}`}
              </div>
            ))}
          </div>
        </div>
        <div className="my-8">
          <div className="text-lg font-bold mb-2">Who can apply:</div>
          <ReactMarkdown className="prose lg:prose-lg prose-purple text-black">
            {graphCmsPosition.whoCanApply.markdown}
          </ReactMarkdown>
        </div>
        <div className="font-bold">
          <div className="text-lg">Perks:</div>
          <div className="mt-4 flex items-center flex-wrap">
            {graphCmsPosition?.perks.map(perk => (
              <div className="py-2 px-3 mr-2 my-2 rounded-full bg-green-100 text-green-900 whitespace-nowrap">
                {`${perk}`}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-8">
          * For any queries email us at{" "}
          <span className="text-primary-500 underline cursor-pointer hover:text-primary-600">
            <a href="mailto:hello@annwntech.com">hello@annwntech.com</a>
          </span>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default PositionDetails

export const query = graphql`
  query modelQuery($id: String!) {
    graphCmsPosition(id: { eq: $id }) {
      name
      location
      duration
      slug
      description {
        markdown
      }
      perks
      skillRequired
      applyBy
      stipend
      whoCanApply {
        markdown
      }
    }
  }
`
